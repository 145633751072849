input:not([type='checkbox']).input {
  font-size: 16px;
  height: 47px;
  border-color: #E0E0E0;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 0;

  &::placeholder {
    font-size: 13px;
  }
}

.input:not([type='checkbox']).input:focus {
  outline: #777 solid 2px!important;
  border: none;
}

.input:not([type='checkbox']).input:hover {
  border-color: #777;
}

.inputWrapper {
  position: relative;
}

.icon {
  position: absolute;
  right: 15px;
  top: 15px;
}
