.label {
  margin-top: 20px;
}

.speakLabel {
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-top: -3px;
  }
}

.twoInputsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  & > * {
    width: 100%;
  }
}

.btn {
  width: unset;
  margin-top: 30px;
  min-width: 330px;

  @media (max-width: 576px) {
    width: 100%;
    min-width: unset;
  }
}

.errorMessage {
  color: red;
  font-size: 12px;
}

.title {
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 30px;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 22px;
    margin-bottom: 25px;
  }
}

.globalWrapper {
  padding: 40px;
  background-color: #fff;

  @media (max-width: 768px) {
    padding: 15px;
  }
}

.fileInput {
//display: none;
}

.fileInputLabel {
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  height: 47px;
  border: #F0500B 1px solid;
  width: unset;
  min-width: 330px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #F0500B;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 130px;

  &:hover {
    background: #F9F9F9;
    border-color: #DB480A;
    color: #DB480A;
  }

  @media (max-width: 576px) {
    width: 100%;
    min-width: unset;
  }
}

.uploadBtn {
  width: unset;
  margin-top: 10px;
  min-width: 330px;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    width: 100%;
    min-width: unset;
  }
}

.preview {
//  position: absolute;
//  top: -117px;
//  left: 0;
//  width: 100px;
//  height: 100px;
//  border-radius: 3px;
//  border: #dddddd dashed 1px;
//  background-size: contain;
//  background-repeat: no-repeat;
}

.certImg {
  max-width: 100%;
  margin-right: 16px;
  margin-bottom: 16px;
}

.submitError {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.multiselectWrapper {
  position: relative;
  font-family: Manrope, sans-serif;
}

.certWrapper {
  position: relative;
  display: inline-block;
}

.closeIcon {
  position: absolute;
  top: -25px;
  right: 8px;
  color: red;
  font-size: 34px;
  cursor: pointer;
}
