.header {
  min-height: 72px;
  z-index: 204;
  background: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1460px) {
    padding: 10px 30px;
    min-height: 62px;
  }

  @media (max-width: 768px) {
    padding: 7px 15px;
  }
}

.logo {
  display: block;
  width: 127px;
  margin-right: 100px;

  img {
    width: 100%;
  }

  @media (max-width: 1320px) {
    width: 100px;
    margin-right: 50px;
  }
}

.navLink {
  padding: 7px 15px;
  margin-right: 30px;
  color: black;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  border-radius: 3px;

  @media (max-width: 1260px) {
    display: none;
  }

  &:hover {
    color: #DB480A;
  }
}

.activeNavLink {
  margin-right: 30px;
  color: #DB480A;;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding: 7px 15px;
  border-radius: 3px;

  @media (max-width: 1260px) {
    display: none;
  }

  &:hover {
    color: #DB480A;;
  }
}

.mainNav {
  display: flex;
  align-items: center;
}

.userNav {
  display: flex;
  align-items: center;
}

.btnText {
  margin-left: 10px;
}

.loginBtn {
  margin-left: 20px;
  margin-right: 10px;

  @media (max-width: 992px) {
    padding: 8px 20px 8px 15px;
    font-size: 14px;
    line-height: 20px;
  }
  @media (max-width: 768px) {
    padding: 5px 15px;
    font-size: 12px;
    line-height: 20px;
  }

  @media (max-width: 576px) {
    margin-left: 0;
  }
}

.wallet {
  display: flex;
  align-items: center;

  img {
    width: 34px;
  }

  &:hover {
    color: #DB480A;
  }
}

.amountBlock {
  background: #F9F9F9;
  border-radius: 3px;
  padding: 7px 17px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.textSuccess {
  font-size: 11px;
}

.walletLink {
  @media (max-width: 576px) {
    display: none;
  }
}

.unread {
  background: red;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  position: absolute;
  top: 0;
  right: 8px;
}

.chatBtn {
  margin-left: 20px;
  margin-right: 20px;
  position: relative;

  @media (max-width: 576px) {
    margin-inline: 0;
  }
}

.userAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border-bottom: #777777 2px solid;
  border-right: #777777 2px solid;
  object-fit: cover;
}

.orangeBtnIcon {
  width: 25px;
  height: 25px;

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
}

.langSelect {
  @media (max-width: 992px) {
    display: none!important;
  }
}

.userLink {
  position: relative;
}

.userName {
  font-size: 16px;
  color: #242424;
  margin-left: 10px;
  margin-right: 10px;

  @media (max-width: 1260px) {
    display: none;
  }
}

.userMenuPopup {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 99;
  background: #fff;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  list-style: none;
  padding: 10px 5px;
  min-width: 230px;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}
