.tutorBenefits {
  display: flex;
  padding: 90px 0;
  color: #242424;
  align-items: center;

  @media (max-width: 768px) {
    padding: 40px 0;
    flex-wrap: wrap;
  }
}

.studentBenefits {
  color: #242424;
  display: flex;
  align-items: center;
  padding-bottom: 90px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

.benefitsDesc {
  width: 50%;
  padding-inline: 15px;

  @media (max-width: 768px) {
    width: 100%;
    padding-inline: 0;
  }
}

.studentBenefitsDesc {
  width: 50%;
  padding-left: 125px;

  @media (max-width: 992px) {
    padding-left: 25px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0;
  }
}

.tutorImgWrapper {
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.studentImgWrapper {
  width: 50%;
  flex-shrink: 0;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.tutorBenImg {
  max-width: 100%;
  width: 483px;
  height: auto;
  object-fit: cover;

  @media (max-width: 992px) {
    max-width: 475px;
    width: 100%;
  }
}

.studentBenImg {
  max-width: 100%;
  width: 475px;
  height: auto;
  object-fit: cover;

  @media (max-width: 992px) {
    max-width: 475px;
    width: 100%;
  }
}

.benTitle {
  font-size: 32px;
  line-height: 36px;
  font-weight: bold;
  margin-bottom: 38px;
  padding-inline: 8px;
  margin-top: 0;

  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 30px;
  }
}

.benListList {
  list-style: none;
  padding: 0;
}

.benItem {
  font-size: 16px;
  line-height: 20px;
  position: relative;
  padding-left: 20px;
  padding-bottom: 20px;

  display: flex;
  align-items: center;


  @media (max-width: 992px) {
    font-size: 14px;
  }

  &:before {
    content: '';
    position: relative;
    left: -20px;
    //top: -5px;

    //top: 22%;
    //transform: translateY(-50%);

    //left: 0;
    background: url(../../../../assets/becomeTutor/checkMark.svg) no-repeat;
    background-size: contain;
    width: 29px;
    height: 29px;
    flex-shrink: 0;

  }


}
