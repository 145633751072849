.wrapper {
  font-size: 14px;
  line-height: 22px;
  color: #242424;
}

.title {
  font-weight: bold;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 22px;
  color: #242424;
}

.list {
  margin-bottom: 16px;
  margin-top: 0;
  padding-left: 26px;
}

.listItem {
  list-style: none;
  position: relative;

  &:before {
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    left: -17px;
    top: 9px;
    position: absolute;
    background-color: #242424;
  }
}


.text {
  margin-bottom: 16px;
  margin-top: 0;
}
