.getPaid {
  padding: 165px 70px 196px 70px;
  background: rgba(40, 40, 40, 0) url(../../../../assets/becomeTutor/become3.webp) no-repeat top center;
  background-size: cover;
  min-height: 200px;

  @media (max-width: 1440px) {
    padding: 165px 30px 196px 30px;
  }

  @media (max-width: 768px) {
    padding: 78px 15px 98px 15px;
  }
}

.title {
  color: white;
  font-weight: bold;
  font-size: 32px;
  line-height: 50px;
  margin: 0;
  @media (max-width: 992px) {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 6px;
  }
}

.subtitle {
  color: white;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 17px;
}

.btn {
  width: 397px;
  min-height: 53px;

  @media (max-width: 992px) {
    font-size: 16px;
    padding: 12px 30px 12px 25px;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 16px;
    padding: 12px 30px 12px 25px;
  }
}
