.formContainer {
    background-color: #a7bcff;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.formContainer .formWrapper {
    background-color: white;
    padding: 20px 60px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}
.formContainer .formWrapper .logo {
    color: #5d5b8d;
    font-weight: bold;
    font-size: 24px;
}
.formContainer .formWrapper .title {
    color: #5d5b8d;
    font-size: 12px;
}
.formContainer .formWrapper form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.formContainer .formWrapper form input {
    padding: 15px;
    border: none;
    width: 250px;
    border-bottom: 1px solid #a7bcff;
}
.formContainer .formWrapper form input::placeholder {
    color: #afafaf;
}
.formContainer .formWrapper form button {
    background-color: #7b96ec;
    color: white;
    padding: 10px;
    font-weight: bold;
    border: none;
    cursor: pointer;
}
.formContainer .formWrapper form label {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #8da4f1;
    font-size: 12px;
    cursor: pointer;
}
.formContainer .formWrapper form label img {
    width: 32px;
}
.formContainer .formWrapper p {
    color: #5d5b8d;
    font-size: 12px;
    margin-top: 10px;
}
.home {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position:fixed;
    top:0;
    right:0;
    width: 440px;
    z-index: 10;
    box-shadow: -15px 0 13px rgb(0 0 0 / 15%);
    background:white;
}
.home .container {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
}
@media screen and (max-width: 768px) {
    /*.home .container {*/
    /*    width: 90%;*/
    /*}*/
}
.home .container .sidebar {
    flex: 1;
    background-color: white;
    position: relative;
}
.home .container .sidebar .navbar {
    display: flex;
    align-items: center;
    background-color: #2f2d52;
    height: 50px;
    padding: 10px;
    justify-content: space-between;
    color: #ddddf7;
}
.home .container .sidebar .navbar .logo {
    font-weight: bold;
}
@media screen and (max-width: 768px) {
    .home .container .sidebar .navbar .logo {
        display: none;
    }
}
.home .container .sidebar .navbar .user {
    display: flex;
    gap: 10px;
}
.home .container .sidebar .navbar .user img {
    background-color: #ddddf7;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    object-fit: cover;
}
.home .container .sidebar .navbar .user button {
    background-color: #5d5b8d;
    color: #ddddf7;
    font-size: 10px;
    border: none;
    cursor: pointer;
}
@media screen and (max-width: 768px) {
    .home .container .sidebar .navbar .user button {
        position: absolute;
        bottom: 10px;
    }
}
.home .container .sidebar .search {
    border-bottom: 1px solid gray;
}
.home .container .sidebar .search .searchForm {
    padding: 10px;
}
.home .container .sidebar .search .searchForm input {
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
}
.home .container .sidebar .search .searchForm input::placeholder {
    color: lightgray;
}
.home .container .sidebar .userChat {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    cursor: pointer;
}
.home .container .sidebar .userChat:hover {
    background-color: #efefef;
}
.home .container .sidebar .userChat img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}
.home .container .sidebar .userChat .userChatInfo span {
    font-size: 18px;
    font-weight: 500;
    color:black;
}
.home .container .sidebar .userChat .userChatInfo p {
    font-size: 14px;
    color: black;
}
.home .container .chat {
    flex: 2;
}
.home .container .chat .chatInfo {
    height: 50px;
    background-color: #5d5b8d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    color: lightgray;
}
.home .container .chat .chatIcons {
    display: flex;
    gap: 10px;
}
.home .container .chat .chatIcons img {
    height: 24px;
    cursor: pointer;
}
.home .container .chat .messages {
    background-color: white;
    padding: 10px;
    height: calc(100% - 50px);
    overflow: auto;
}
.home .container .chat .messages .message {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}
.home .container .chat .messages .message .messageInfo {
    display: flex;
    flex-direction: column;
    color: gray;
    font-weight: 300;
}
.home .container .chat .messages .message .messageInfo img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.home .container .chat .messages .message .messageContent {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.home .container .chat .messages .message .messageContent p {
    background-color: #FFE33C;
    padding: 10px 20px;
    border-radius: 0px 10px 10px 10px;
    max-width: max-content;
}
.home .container .chat .messages .message .messageContent img {
    width: 50%;
}
.home .container .chat .messages .message.owner {
    flex-direction: row-reverse;
}
.home .container .chat .messages .message.owner .messageContent {
    align-items: flex-end;
}
.home .container .chat .messages .message.owner .messageContent p {
    background-color: #f5f2f2;
    color: black;
    border-radius: 10px 0px 10px 10px;
}
.home .container .chat .input {
    height: 72px;
    background-color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.home .container .chat .input input {
    width: 100%;
    border: none;
    outline: none;
    color: #2f2d52;
    font-size: 18px;
}
.home .container .chat .input input::placeholder {
    color: lightgray;
}
.home .container .chat .input .send {
    display: flex;
    align-items: center;
    gap: 10px;
}
.home .container .chat .input .send img {
    height: 24px;
    cursor: pointer;
}
.home .container .chat .input .send button {
    border: none;
    padding: 10px 15px;
    color: white;
    background-color: #8da4f1;
    cursor: pointer;
}
.chatClose{
    height: 40px;
    top:40px;
    cursor: pointer;
    position: absolute;
    left:-8px;
}
.chatCloseBtn{
    position:absolute;
    left:-8px;
    top:0px;
    cursor: pointer;
    background: #bbbbbb;
    padding: 5px;
    z-index: 9;
    border-radius: 50px;
}
.chatMainImg{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}
.attachFile{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80px;
}
.btn-close{
    opacity:1;
}
@media screen and (max-width: 768px) {
    /*.home {*/
    /*    width: 100%;*/
    /*    height: 100%;*/
    /*}*/
    /*.input{*/
    /*    position: fixed;*/
    /*    bottom:0;*/
    /*}*/
}