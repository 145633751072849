.error {
  font-size: 13px;
  color: red;
}

.errorInput {
  input {
    border: red solid 1px !important;
  }
}

