.menuWrapper {
  position: absolute;
  right: 0;
  top: 0;
  width: 85%;
  max-width: 370px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 999999;
  padding: 55px 0 0 25px;
  overflow-y: scroll;
  height: calc( var(--myVh) * 100 );
}

.menuContent {
  width: 320px;

  @media (max-width: 576px) {
    width: 270px;
  }
}

.hamburger {
  position: relative;
  display: none;
  margin-left: 10px;
  top: -3px;
  cursor: pointer;

  @media (max-width: 992px) {
    display: block;
    margin-right: 5px;
  }
}

.hamburger span {
  display: inline-block;
  width: 29px;
  height: 3px;
  background: #242424;
  position: relative;
  right: -4px;

  &:before {
    content: "";
    width: 29px;
    height: 3px;
    background: #242424;
    position: absolute;
    top: -9px;
    left: -5px;
  }

  &:after {
    content: "";
    width: 29px;
    height: 3px;
    background: #242424;
    position: absolute;
    top: 9px;
    left: -5px;
  }
}

.closeBtn {
  position: absolute;
  right: 15px;
  top: 15px;
  border: none;
  outline: none;
  background-color: transparent;
  font-weight: normal;
  font-size: 22px;
  color: black!important;
}

.langSelect {
  margin-bottom: 20px;
}

.mobileNav {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #E0E0E0;

  a {
    &:hover {
      color: #242424;
    }
  }

  li {
    font-size: 16px;
    line-height: 38px;
    color: #242424;

    &:first-child {
      margin-top: 5px;
    }
  }
}

.logoutItem {
  color: #dc3545;
}

.searchField {
  margin-bottom: 20px;
}

.wallet {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  img {
    width: 34px;
    position: relative;
    bottom: 4px;
  }

  &:hover {
    color: #DB480A;
  }
}

.amountBlock {
  background: #F9F9F9;
  border-radius: 3px;
  padding: 7px 17px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.textSuccess {
  font-size: 11px;
  line-height: 20px;
}

