.feedbackTextarea {
  width: 100%;
  margin-bottom: 1rem;
}

.starRating {
    display: flex;
    justify-content: center;
  span {
    font-size: 40px;
    cursor: pointer;
    margin-right: 0.5rem;
  }
}

.submitError {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
