.globalWrapper {
  display: flex;
  gap: 25px;
  padding-top: 60px;
  padding-bottom: 50px;

  @media (max-width: 992px) {
    padding-top: 30px;
  }
}

.menu {
  a {
    margin-bottom: 15px;
  }
}

.menuPart {
  width: 22%;

  @media (max-width: 992px) {
    display: none;
  }
}

.contentPart {
  width: 75%;
  @media (max-width: 992px) {
    width: 100%;
  }
}
