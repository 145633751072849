.form {
  display: flex;
  justify-content: space-between;
  width: 400px;
  color: white;
  flex-wrap: wrap;
  align-items: flex-start;

  input {
    margin-bottom: 0px;
  }

  & > * {
    width: 100%;
  }

  @media (max-width: 992px) {
    width: 100%;
    background: transparent;
    padding: 25px 30px;
    color: #242424;
  }

  @media (max-width: 768px) {
    padding: 25px 15px;
  }
}

.formTitle {
  font-weight: bold;
  margin: 0;
  font-size: 32px;
  line-height: 50px;

  @media (max-width: 768px) {
    line-height: 38px;
  }
}

.formSubtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 40px;
}

.halfInput {
  width: 48.5%;
}

.formLabel {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  color: #fff;
  display: block;
  margin-bottom: 0;
  margin-top: 10px;


  & > * {
    color: #242424;
  }

  @media (max-width: 992px) {
    color: #242424;
  }
}

.errorMessage {
  color: red;
  margin-top: 0px;
  font-size: 12px;
}

.error {
  input {
    border: red 1px solid!important;
  }

  input:hover {
    border: red 1px solid!important;
  }

}

.underMessage {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 12px;

  @media (max-width: 992px) {
    color: #242424;
  }
}

.btn {
  margin-top: 20px;

  @media (max-width: 992px) {
    font-size: 16px;
    padding: 12px 30px 12px 25px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 12px 30px 12px 25px;
  }
}

.select {
  margin-bottom: 0;
}

.serverErrors {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%);
  color: white;
  background-color: orangered;
  padding: 20px;
  min-width: 400px;
  border-radius: 15px;
  font-size: 16px;
  line-height: 35px;

}
