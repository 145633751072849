/*@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');*/

* {
  box-sizing: border-box;
}

html, body, #root, #defaultLayout, #guestLayout {
  min-height: 100vh;
  scroll-behavior: auto !important;

}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

body {
  font-family: 'Manrope', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #242424;
  background-color: #f6f6f6;
  position: relative;
}

input:not([type='checkbox']), select {
  outline: 0;
  background: #ffffff;
  width: 100%;
  border: 2px solid #e6e6e6;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  transition: all 0.3s;
}

input:focus {
  border-color: #f0500b;
}

.btn,
.btn-add,
.btn-edit,
.btn-delete {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f0500b;
  border: 0;
  text-decoration: none;
  padding: 10px 15px;
  color: #FFFFFF;
  font-size: 16px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.btn-block {
  width: 100%;
}

.btn-add,
.btn-edit,
.btn-delete {
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  border-radius: 4px;
}

.btn-add {
  background-color: #00a762;
}

.btn-delete {
  background-color: #b72424;
}

.btn-logout {
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  color: #212121;
  transition: all 0.3s;
  border-radius: 6px;
}

.btn-logout:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.btn:hover,
.btn:active,
.btn:focus {
  background: #f0500b;
}

.text-center {
  text-align: center;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

table > thead > tr > th {
  text-align: left;
  padding: 0.5rem 0.5rem;
  background-color: #efefef;
}

table > tbody > tr > td {
  padding: 0.5rem 0.5rem;
  border-bottom: 1px solid #efefef;
  white-space: nowrap;
}

.card {
  background-color: #FFF;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.25rem 1.5rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.alert {
  padding: 1rem;
  background-color: #ff4040;
  color: white;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.success-block {
  padding: 1rem;
  background-color: #31b24b;
  color: white;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.notification {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 100;
  padding: 1rem 1.5rem;
  background-color: #00a762;
  color: white;
  border-radius: 0.5rem;
}

/* Login/Signup forms*/

.login-signup-form {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-signup-form .form {
  width: 360px;
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  padding: 34px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}

.login-signup-form .title {
  font-size: 20px;
  margin-bottom: 1rem;
  text-align: center;
}

.login-signup-form .form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 16px;
  text-align: center;
}

.login-signup-form .form .message a {
  color: #f0500b;
  text-decoration: none;
}

.login-signup-form .forgot-password {
  margin: 15px 0 0;
  a {
    color: #f0500b;
    font-weight: bold;
  }
}


/* Login/Signup form */


#defaultLayout {
  display: flex;
}

#defaultLayout aside {
  width: 240px;
  background-color: #f0500b;
  padding: 1rem
}

#defaultLayout aside > a {
  display: block;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  color: white;
  text-decoration: none;
  transition: all 0.2s;
}

#defaultLayout aside > a:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

#defaultLayout .content {
  flex: 1;
}

#defaultLayout header {
  height: 80px;
  padding: 2rem 3rem;
  background-color: white;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#defaultLayout main {
  padding: 2rem;
}

.animated {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.container-1312 {
  margin: 0 auto;
  max-width: 1312px;
  width: 100%;
  padding-inline: 15px;
}

.container {
  margin: 0 auto;
  max-width: 1100px;
  width: 100%;
  padding-inline: 15px;


  @media (max-width: 1000px) {
    //max-width: 768px;
  }

  @media (max-width: 768px) {
    //max-width: 576px;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
}

.transparentOverlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.whiteOverlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgb(255,255,255, .8);
  z-index: 999;
}

.normalBtn {
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
}

.overlay {
  z-index: 1;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0,.5);
}

.overlay1 {
  z-index: 1;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0,.2);
}

.myLessonsTitle {
  margin-top: 20px;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 40px;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 22px;
    margin-bottom: 20px;
  }
}

.profilePageTitle {

  font-size: 36px;
  line-height: 40px;
  margin-bottom: 40px;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 22px;
    margin-bottom: 20px;
  }
}


.titleOrderHistory {
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 20px;
  font-weight: 700;


  @media (max-width: 768px) {
    font-size: 22px;
    margin-bottom: 0px;
  }
}

.innerPageScroll {
  overflow-x: auto;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }
}
