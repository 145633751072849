
.filtersBtn {
  font-weight: 400 !important;
  display: none;
  cursor: pointer;
  text-align: right;
  font-size: 12px;
  line-height: 24px;
  color: #666666;
  background-color: white;
  align-items: center;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  padding: 0 12px 0 11px;
  height: 31px;
  margin-bottom: 15px;

  &:focus, &:hover {
    color: #666666 !important;
  }

  img {
    margin-left: 15px;
  }

  @media (max-width: 992px) {
    display: flex;
  }
}

.filtersWrapper {
  overflow-y: scroll;
  height: calc( var(--myVh) * 100 );
  position: fixed;
  //position: absolute;
  left: 0;
  top: 0;
  width: 85%;
  padding: 65px 0 30px 15px;
  max-width: 310px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 999999;

  @media (max-width: 992px) {
    padding: 65px 0 0 15px;
    max-width: 310px;
  }
}


.filterContent {
  width: 280px;

  @media (max-width: 576px) {
    //width: 270px;
  }
}

.closeBtn {
  position: absolute;
  right: 15px;
  top: 15px;
  border: none;
  outline: none;
  background-color: transparent;
  font-weight: normal;
  font-size: 22px;
  color: black !important;

  &:hover {
    color: black !important;
  }
}

.mobileFilters {
  padding: 0;
}



