.formWrapper {
  padding: 40px 50px;
  max-width: 540px;
  width: 100%;
  margin: 0 auto;
  background: white;
}

.logo {
  text-align: center;
  margin-bottom: 20px;
}

.wrapper {
  margin-bottom: 26px;
}
