.title {
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 40px;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 22px;
    margin-bottom: 35px;
  }
}
