.userMenu {
  color: #242424;
  a {
    display: flex;
    align-items: center;
    font-size: 16px;

    &:hover {
      color: #DB480A!important;
    }
  }
}

.menuWrapper {
  padding: 5px 5px;
}
