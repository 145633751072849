.verify {
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
  border-left: 5px solid  #a12126;
  color: #fff;
  background-color: #dd3546;
}

.verifyBtn {
  width: unset;
  margin-top: 30px;
  margin-inline: auto;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
}

.resendLink {
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  text-decoration: underline;

  &:hover {
    color: #fff;
  }
}
