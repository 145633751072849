.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  fill: #000;
  position: relative;
  background-color: #fff;
  z-index: 1;
}

.langWrapper {
  width: 100px;
  &:hover {
    color: #F0500B;
    fill: #F0500B;
  }
}

.langName {
  display: inline-block;
  font-size: 16px;
  margin-left: 5px;
}

.dropdown {
  position: absolute;
  background-color: #fff;
  width: 155px;
  max-width: 900px;
  box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  bottom: -158px;
  left: 0px;
  border-radius: 5px;
  color: #000;

  &:hover {
    color: #000;
  }
}

.dropList {
  margin-bottom: 0;
  padding: 15px 10px 10px 10px;

  li {
    font-size: 16px;
    padding: 0;
    cursor: pointer;
    list-style: none;
    margin-bottom: 15px;
  }
}
