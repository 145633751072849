.btn {
  text-decoration: none;
  padding: 12px 20px 12px 20px;
  font-size: 16px;
  line-height: 24px;
  background: #fff;
  color: #F0500B;
  text-transform: uppercase;
  justify-content: center;
  border-radius: 4px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  font-weight: 700 !important;
  margin-top: 0;

  &:hover {
    background: #DB480A;
    color: #fff;
  }

  &:disabled {
    background: rgb(184, 142, 129);
  }
}

