.filterForm {
  height: 100%;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #e0e0e0;
  padding: 25px;

  //@media (max-width: 992px) {
  //  border: none
  //}


  @media (max-width: 992px) {
    border: none;
    padding: 0px;
    padding-bottom: 30px;

  }
}

.formLabel {
  display: block;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}

.checkBoxGroup {
  margin-top: 30px;
}

.searchBtn {
  width: 100%;
  background: transparent;
  color: #F0500B;
  font-size: 16px;
  line-height: 30px;
  padding: 0 25px;
  height: 53px !important;
  font-weight: 799 !important;
  margin-top: 20px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #F0500B;
  border-radius: 3px;
  margin-bottom: 60px;

  &:hover {
    background: #F9F9F9;
    border-color: #DB480A;
    color: #DB480A !important;
  }
}

.closeBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  border: none;
  outline: none;
  background-color: transparent;
  font-weight: normal;
  font-size: 22px;

  &:hover {
    color: black !important;
  }
}

.searchField {
  margin-bottom: 15px;
}

