.certItem {
  border: #efefef 1px solid;
  padding: 0;
  margin-right: 16px;
  margin-bottom: 16px;

  img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    max-width: 100%;
  }
}

.modal {
  width: 500px;
  max-width: 100%;
  padding-inline: 15px;
  height: auto;
  border-radius: 10px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.img {
  z-index: 1000;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.closeBtn {
  position: absolute;
  right: 25px;
  top: 10px;
  font-size: 50px;
  font-weight: 200!important;
  line-height: 30px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    color: #F0500B;
  }
}
