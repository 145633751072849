.video {
  width: 100%;
  height: 400px;
  border-radius: 3px;
  overflow: hidden ;

  @media (max-width: 992px) {
    height: 310px;
  }

  @media (max-width: 768px) {
    height: 210px;
  }
}

.utubeWrapper {
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 16px;
}

.playBtn {
  display: block;
  opacity: 0.8;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 118px;
  height: 118px;
  background-image: url(../../../../assets/playBtn.svg);
  background-size: contain;
  border: none;
  outline: none;
  background-color: transparent;
  object-fit: cover;

  @media (max-width: 992px) {
    width: 80px;
    height: 80px;

  }
}


.img {
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
