.popup {
  z-index: 999;
  color: #242424;
  max-width: 600px;
  background-color: white;
  padding: 50px 35px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 600px;
  min-height: 100px;

  @media (max-width: 768px) {
    min-width: unset;
    width: calc(100% - 15px);
    top: 70px;
    transform: translate(-50%, 0);
  }
}

.closeBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 50px;

  font-weight: 200!important;
  line-height: 30px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    color: #F0500B;
  }
}
