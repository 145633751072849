.searchWrapper {
  position: relative;
}

.searchBtn {
  position: absolute;
  right: 2px;
  top: 10px;
  border: none;
  outline: none;
  background-color: transparent;
}
